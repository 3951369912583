<template>
  <v-container fluid>
    <v-row>
      <v-row>
        <v-col>
          <template>
            <v-card>
              <v-data-table
                :headers="orderHeaders"
                :items="recentOrders"
                :search="search"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                :loading="loading"
                loading-text="Loading... Please wait"
                no-data-text="No orders available"
                item-key="id"
                show-expand
                class="elevation-0"
                style="background: #f5f5f5"
              >
                <template v-slot:top>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <div class="pr-5 pb-5 pt-2">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        filled
                        rounded
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                  </v-card-title>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <b>Order Refrence: </b> {{ item.id }} <br />
                    <b>User ID : </b> {{ item.user_id }}<br />
                    <b
                      >Order Items :
                      <span
                        v-for="(product, index) in item.orders"
                        :key="index"
                        class="font-weight-medium"
                        >{{ product.product_name }} ,
                      </span></b
                    >
                    <br />
                    <b>Date : </b> {{ item.ordered_date }}<br />
                    <b>Host Site : </b> {{ item.host }}<br />
                    <b>Order Status : </b> {{ item.order_status }}<br />
                    <b>Delivery Type : </b> {{ item.delivery_status }}<br />
                    <b>Copun Code : </b> {{ item.coupon_code }}<br />
                    <b>Copun Percentage : </b>
                    {{ item.coupon_percentage }}<br />
                    <b>Total Point : </b> {{ item.point_total }}<br />
                  </td>
                </template>

                <template v-slot:item.coupon="{ item }">
                  <div v-if="item.coupon_code">{{ item.coupon_code }}</div>
                  <div v-else>N/A</div>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="primary"
                    style="padding-left: 0px !important"
                    text
                    @click="viewDetailsToConfirmOrder(item)"
                    >Details</v-btn
                  >
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    expanded: [],
    loading: true,
    search: "",
    singleExpand: false,
    orderHeaders: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
      },
      { text: "Date", value: "ordered_date" },
      { text: "Price ", value: "price_total" },
      { text: "Payment Status", value: "order_status" },
      { text: "Delivery Status", value: "delivery_status" },
      { text: "Coupon Code", value: "coupon" },
      { text: "Coupon Percentage", value: "coupon_percentage" },
      { text: "Total Points", value: "point_total" },
      // { text: "Admin Confirmation", value: "adminaction", sortable: false },
      { text: "", value: "actions", sortable: false },
      // { text: 'test', value: 'tes' },
      { text: "", value: "data-table-expand" },
    ],
    recentOrders: [],
    user : {}
  }),
  methods: {
    initialize() {
      axios.get(`Cart/merchant_orders_pending/${this.user.user_id}`).then((response) => {
        this.loading = false;
        if (response.data.success) {
          this.recentOrders = response.data.data;
        }
      });
    },
    viewDetailsToConfirmOrder(item) {
      this.$router.push({
        path: "/merchantdashboard/pages/confirmproduct",
        query: { id: item.id },
      });
    },
  },

  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.role && this.user.role.toLowerCase() == "seller") {
      this.initialize();
    }
  },
};
</script>

<style>
</style>